import React, { useContext, useState } from 'react';
import { UsersContext } from '../context/UsersProvider';
import {
  CenteredColumnBox,
  FakeLinksButton,
  MyColumnBox,
  MyUtilityButton,
  MyUtilityForm,
  MyUtilityInput,
  MyUtilityLabel,
} from '../components/styles/DarkStyles';
import styled from 'styled-components';
import meeko from '../images/download.png';

export const Login = () => {
  const { login, setLoginMenu, loginMenu, setLogin } = useContext(UsersContext);

  const CenteredColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5%;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
    border-radius: 10px;
    min-width: 20%;
    min-height: 35%;
    h2 {
      margin: 15px 0px 0px 0px;
    }
  `;

  const MyUtilityButton = styled.button`
    background-color: #444;
    font-size: 1.2em;
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    margin: 10px 0px 0px 0px
    cursor: pointer;
    margin: 15px 0px 0px 0px;
  `;

  const MyUtilityLabel = styled.label`
    margin: 5px;
  `;

  const MyUtilityInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin: 5px;
  `;

  const MyColumnBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 10px 0px 0px 0px;

    justify-content: center;
  `;
  const MyUtilityForm = styled.form`
    display: flex;
    flex-direction: column;
    margin: 25px;
    justify-content: center;
    align-items: center;
  `;

  if (loginMenu === 'Register') {
    return (
      <CenteredColumnBox isLogin className='loginContainer'>
        <h2>Register</h2>
        <MyUtilityForm
          onSubmit={(e) => {
            e.preventDefault();
            setLogin(!login);
            console.log('The login has been set: ' + login);
          }}
        >
          <MyColumnBox>
            <MyUtilityLabel htmlFor='email'>Email</MyUtilityLabel>
            <MyUtilityInput type='email' id='email' />
          </MyColumnBox>
          <MyColumnBox>
            <MyUtilityLabel htmlFor='password'>Password</MyUtilityLabel>
            <MyUtilityInput type='password' id='password' />
          </MyColumnBox>
          <MyColumnBox>
            <MyUtilityLabel htmlFor='password'>Confirm Password</MyUtilityLabel>
            <MyUtilityInput type='password' id='passwordConf' />
          </MyColumnBox>
        </MyUtilityForm>
        <MyUtilityButton
          onClick={() => {
            alert('Just Kidding, Only Carl Palumbos can register');
          }}
          type='submit'
        >
          Login
        </MyUtilityButton>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p>Already have an account?</p>
          <button
            style={{
              background: 'none',
              color: 'green',
              border: 'none',
              fontSize: '16px',
            }}
            onClick={(e) => setLoginMenu('Login')}
          >
            Login
          </button>
        </div>
      </CenteredColumnBox>
    );
  } else {
    return (
      <CenteredColumnBox isLogin className='loginContainer'>
        <img src={meeko} alt='meeko' className='App-meeko' width={'150px'} />

        <h2>Login</h2>
        <MyUtilityForm
          onSubmit={(e) => {
            e.preventDefault();
            setLogin(!login);
            console.log('The login has been set: ' + login);
          }}
        >
          <MyColumnBox>
            <MyUtilityLabel htmlFor='email'>Email</MyUtilityLabel>
            <MyUtilityInput type='email' id='email'></MyUtilityInput>
          </MyColumnBox>
          <MyColumnBox>
            <MyUtilityLabel htmlFor='password'>Password</MyUtilityLabel>
            <MyUtilityInput type='password' id='password' />
          </MyColumnBox>
          <MyUtilityButton type='submit'>Login</MyUtilityButton>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>Don't have an account? </p>
            <button
              style={{
                background: 'none',
                color: 'green',
                border: 'none',
                fontSize: '16px',
              }}
              onClick={(e) => setLoginMenu('Register')}
            >
              Register Here
            </button>
          </div>
        </MyUtilityForm>
      </CenteredColumnBox>
    );
  }
};

/*


*/
